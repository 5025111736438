import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate"
import user from './modules/user'
import cart from './modules/cart'
import order from './modules/order'
import product from './modules/product'
Vue.use(Vuex);

const store = new Vuex.Store({
    modules: {
      user,
      cart,
      order,
      product
    },
    plugins: [createPersistedState()]
});

export default store;