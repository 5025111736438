import Vue from 'vue'
import ToastHtml from './index.vue'

let ToastConstructor = Vue.extend(ToastHtml)
let instance = new ToastConstructor()
let vm = instance.$mount()

const ToastPlugin = (options = {}) => {
    document.querySelector('body').appendChild(vm.$el)
    instance.show(options)
}
export default ToastPlugin