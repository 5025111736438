import Vue from 'vue'
import App from './App.vue';
import Vuex from 'vuex';
import router from './router/index';
import filter from './utils/filter';
import store from './store/index';
import Element from 'element-ui';
import axios from './utils/http'
import VueAxios from 'vue-axios'
import plugins from './plugins'
import 'animate.css'
import 'element-ui/lib/theme-chalk/index.css';
import validate from './utils/validate'
import utils from './utils/utils'
import API from './api'
// import '../theme/index.css'
import VueAwesomeSwiper from 'vue-awesome-swiper'
Vue.use(VueAwesomeSwiper)
Vue.use(Vuex);
Vue.use(filter);
Vue.use(Element);
Vue.use(VueAxios, axios)
Vue.use(plugins)
Vue.prototype.$check = validate
Vue.prototype.$utils = utils
Vue.prototype.$API = API
Vue.config.productionTip = false

export default new Vue({
  store,
  router,
  render: h => h(App),
    mounted () {
        document.dispatchEvent(new Event('custom-render-trigger'))
    }

}).$mount('#app')


