<template>
  <div id="app">
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  </div>
</template>

<script>
export default {
  name: 'app',
  watch: {
    $route: {
      handler() {
         window.scrollTo(0, 0)
      },
      immediate: true,
      deep: true
    }
  },
    // mounted() {
    //     if (this._isMobile()) {
    //         window.location.href="https://www.kqwad.com/mobile/";
    //         // window.location.href="http://127.0.0.1:8081/mobile/";
    //     }
    // },
    // methods:{
    //     _isMobile() {
    //         let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
    //         return flag;
    //     }
    // }
}
</script>

<style lang='scss'>
@import './assets/scss/common.scss';
@import './assets/scss/reset.scss';
#app {
  width: 100%;
  height: 100%;
  font-family: 'Microsoft YaHei', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
