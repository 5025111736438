/*
 * @Author: Rain
 * @Description: axios类
 * @Date: 2019-04-11 16:58:58
 */
import axios from 'axios';
import app from '@/main';
const ajaxParam = {
    baseURL:
    process.env === 'development' ? '/_api' : 'https://www.kqwad.com/pcapi',
    // process.env === 'development' ? '/_api' : 'http://47.111.186.12:8081',
    // process.env === 'development' ? '/_api' : 'http://192.168.1.175:8081',
    timeout: 5000,
    headers: { timeStamp: new Date().getTime() }
};
Object.assign(axios.defaults, ajaxParam);

axios.interceptors.response.use(
  response => {
    const { code, msg } = response.data;
    if (code === 400 || code === 403) {

      app.$message({
        message: msg,
        duration: 1000,
        type: 'error',
        onClose: () => {
          localStorage.removeItem('TOKEN');
          app.$router.push({
            name: 'login'
          });
        }
      });
    }else if(code * 1 !== 0){

      if (response.config.url.indexOf("excel/export")!=-1 && code===undefined){
      }else{
        app.$message({
          type: 'error',
          message: msg
        });
      }

      //alert(msg);
    }
    return response;
  },
  error => {
    const { msg, code } = error.response.data;
    if (code === 403 || code === 400) {
      app.$message({
        message: msg,
        type: 'error',
        duration: 1000,
        onClose: () => {
          localStorage.removeItem('TOKEN');
          app.$router.push({
            name: 'login'
          });
        }
      });
    } else {
      app.$message({
        message: msg,
        type: 'error'
      });
    }
    return Promise.reject(error);
  }
);
axios.interceptors.request.use(config => {
  if (localStorage.getItem('TOKEN')) {
    config.headers['Authorization'] = localStorage.getItem('TOKEN');
  }
  return config;
});
export default axios;
