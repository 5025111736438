export default {
  /**
   * @description: 验证手机号格式
   * @param {type}
   * @return:
   */
  phone: val => {
    return /^[1]([3-9])[0-9]{9}$/.test(val);
  },
  /**
   * @description:验证密码格式
   * @param {type}
   * @return:
   */
  password: val => {
    return /^(?!^(\d+|[a-zA-Z]+|[~!@#$%^&*?]+)$)^[\w~!@#$%^&*?]{8,18}$/.test(
      val
    );
  },
  /**
   * @description: 去除空格
   * @param {type}
   * @return:
   */
  trim: (val = '') => {
    return val.replace(/(^\s*)|(\s*$)/g, '');
  },
  checkMoney: val => {
    return /(^[1-9]([0-9]+)?(\.[0-9]{1,2})?$)|(^(0){1}$)|(^[0-9]\.[0-9]([0-9])?$)/.test(val);
  }
};
