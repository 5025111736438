/*
 * @Author: Rain
 * @Description: 订单信息
 * @Date: 2019-04-10 17:37:18
 */
import { ORDER_ENTRY, ORDER_PRODUCT , STORE_INFO, PAY_INFO} from './types';
const state = {
  orderType: '', //订单类型
  orderEntry: 0, // 订单入口(0:购物车,1:产即下单)
  product: {}, //单个商品下单时,商品对象
  store: {}, //商品对应的店铺对象
  payObj: {} //支付对象
};
const mutations = {
  [ORDER_ENTRY](state, payload) {
    state.orderEntry = payload;
  },
  [ORDER_PRODUCT](state, payload) {
    state.product = payload;
  },
  [STORE_INFO](state, payload){
    state.store = payload
  },
  [PAY_INFO](state, payload){
    state.payObj = payload
  }
};
const actions = {};
const getters = {
  orderType: state => state.orderType,
  orderEntry: state => state.orderEntry,
  product: state => state.product,
  store: state => state.store,
  payObj: state => state.payObj
};
export default {
  state,
  mutations,
  actions,
  getters
};
