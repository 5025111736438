import Vue from 'vue';
import Router from 'vue-router';
// 订单相关
Vue.use(Router);
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) { return originalPush.call(this, location).catch(err => err) };

export default new Router({
    // mode: 'history',
      // base:'/pc',     //应用基路经，在网站根目录可不用设置
    routes: [
        {
            path: '/',
            name: 'home',
            // redirect: '/information',
            component: () =>
                import(/* webpackChunkName: "view-main" */ `@/views/index`),
            children: [
                {
                    path: '/',
                    name: 'main',
                    meta:{
                        topImgShow:true,
                    },
                    component: () =>
                        import(/* webpackChunkName: "home" */ `@/views/home/index`)
                },
                {
                    path: '/searchshop',
                    name: 'searchshop',
                    component: () =>
                        import(/* webpackChunkName: "searchshop" */ `@/components/common/searchshop`)
                },
                {
                    path: '/searchProduct',
                    name: 'searchProduct',
                    component: () =>
                        import(/* webpackChunkName: "searchProduct" */ `@/components/common/searchProduct`)
                },
                {
                    path: '/classSearch/:id',
                    name: 'classSearch',
                    component: () =>
                        import(/* webpackChunkName: "classSearch" */ `@/components/common/classSearch`)
                },
                {
                    path: '/dynamicList',
                    name: 'dynamicList',
                    component: () =>
                        import(/* webpackChunkName: "dynamicList" */ `@/views/home/dynamicList`)
                },
                {
                    path: '/dynamicDetail/:id/:detailId',
                    name: 'dynamicDetail',
                    component: () =>
                        import(/* webpackChunkName: "dynamicDetail" */ `@/views/home/dynamicDetail`)
                },
                {
                    path: '/product/:id',
                    name: 'product',
                    component: () =>
                        import(/* webpackChunkName: "product" */ `@/views/product/index`)
                },
                {
                    path: '/productList',
                    name: 'productList',
                    component: () => import(/* webpackChunkName: "productList" */ `@/views/productList/index`)
                },
                {
                    path: '/order',
                    name: 'order',
                    component: () => import(/* webpackChunkName: "order" */ `@/views/order/index.vue`)
                },
                {
                    path: '/paystatus/:orderSn',
                    name: 'paystatus',
                    component: () => import(/* webpackChunkName: "productList" */ `@/views/order/paystatus.vue`)
                },
                {
                    path: '/paySuccess/:oid',
                    name: 'paySuccess',
                    component: () => import(/* webpackChunkName: "paySuccess" */ `@/views/order/paySuccess.vue`)
                },
                {
                    path: '/payFail',
                    name: 'payFail',
                    component: () => import(/* webpackChunkName: "payFail" */ `@/views/order/payFail.vue`)
                },
                {
                    path: '/return',
                    name: 'return',
                    component: () => import(/* webpackChunkName: "return" */ `@/views/order/return.vue`)
                },
                {
                    path: '/productDetail',
                    name: 'productDetail',
                    component: () => import(/* webpackChunkName: "productDetail" */ `@/views/productDetail/index`)
                },
                {
                    path: '/shopPage/:id',
                    name: 'shopPage',
                    component: () => import(/* webpackChunkName: "shopPage" */ `@/views/shopPage/index`)
                },
                {
                    path: '/caseDetail',
                    name: 'caseDetail',
                    component: () => import(/* webpackChunkName: "caseDetail" */ `@/views/shopPage/caseDetail/index`)
                },
                {
                    path: '/infoList',
                    name: 'infoList',
                    component: () => import(/* webpackChunkName: "infoList" */ `@/views/infoList/index`)
                },
                {
                    path: '/infoDetail',
                    name: 'infoDetail',
                    component: () => import(/* webpackChunkName: "infoDetail" */ `@/views/infoList/infoDetail/index`)
                },
                {
                    path: '/requirement',
                    name: 'requirement',
                    component: () => import(/* webpackChunkName: "requirement" */ `@/views/requirement/index`)
                },
                {
                    path: '/requirementSuccess',
                    name: 'requirementSuccess',
                    component: () => import(/* webpackChunkName: "requirementSuccess" */ `@/views/requirement/requirementSuccess/index`)
                },
                {
                    path: '/help',
                    name: 'help',
                    component: () => import(/* webpackChunkName: "help" */ `@/views/help/index`)
                },
                {
                    path: '/shopSelect',
                    name: 'shopSelect',
                    component: () => import(/* webpackChunkName: "shopSelect" */ `@/views/shopSelect/index`)
                },
                {
                    path: '/renting',
                    name: 'renting',
                    component: () => import(/* webpackChunkName: "renting" */ `@/views/helpCenter/renting`)
                },
                {
                    path: '/helpCenter/:id',
                    name: 'helpCenter',
                    component: () => import(/* webpackChunkName: "helpCenter" */ `@/views/helpCenter/index`)
                },

                // 个人中心
                {
                    path: '/quoteMerchant/:id/',
                    name: 'quoteMerchant',
                    component: () =>
                        import(/* webpackChunkName: "quoteMerchant" */ `@/views/mycenter/deal/quoteMerchant`)
                },
                {
                    path: '/demandDetail/:id/:storeId/',//需求详情=>详情
                    name: 'demandDetail',
                    component: () =>
                        import(/* webpackChunkName: "demandDetail" */ `@/views/mycenter/deal/demandDetail`)
                },


                {
                    path: '/orderNeed',//需求详情=>详情=》需求确认
                    name: 'orderNeed',
                    component: () =>
                        import(/* webpackChunkName: "demandDetail" */ `@/views/mycenter/deal/orderNeed`)
                },

                {
                    path: '/orderPrice',//需求详情=>详情=》比价订单确认
                    name: 'orderPrice',
                    component: () =>
                        import(/* webpackChunkName: "orderPrice" */ `@/views/mycenter/deal/orderPrice`)
                },

                {
                    path: '/priceDetail/:id/',
                    name: 'priceDetail',
                    component: () =>
                        import(/* webpackChunkName: "priceDetail" */ `@/views/mycenter/deal/priceDetail`)
                },
                {
                    path: '/join',
                    name: 'join',
                    component: () =>
                        import(/* webpackChunkName: "priceDetail" */ `@/views/apply/index`)
                },
                {
                    path: '/success',
                    name:'success',
                    component:()=>import(/* webpackChunkName: "success"*/ `@/views/apply/success`)
                },
                {
                    path: '/shoppingCart',
                    name: 'shoppingCart',
                    component: () =>
                        import(/* webpackChunkName: "priceDetail" */ `@/views/shoppingCart/index`)
                },
                {
                    path: '/center',
                    name: 'center',
                    redirect: '/basic',
                    component: () =>
                        import(/* webpackChunkName: "mycenter" */ `@/views/mycenter/index`),
                    children: [
                        //账号管理
                        {
                            path: '/basic',
                            name: 'basic',
                            component: () =>
                                import(/* webpackChunkName: "basic" */ `@/views/mycenter/manage/basic`)
                        },
                        {
                            path: '/address',
                            name: 'address',
                            component: () =>
                                import(/* webpackChunkName: "address" */ `@/views/mycenter/manage/address`)
                        },
                        {
                            path: '/invoice',
                            name: 'invoice',
                            component: () =>
                                import(/* webpackChunkName: "invoice" */ `@/views/mycenter/manage/invoice`)
                        },
                        {
                            path: '/logonbind',
                            name: 'logonbind',
                            component: () =>
                                import(/* webpackChunkName: "logonbind" */ `@/views/mycenter/manage/logonbind`)
                        },
                        {
                            path: '/phonebind',
                            name: 'phonebind',
                            component: () =>
                                import(/* webpackChunkName: "phonebind" */ `@/views/mycenter/manage/phonebind`)
                        },
                        {
                            path: '/security',
                            name: 'security',
                            component: () =>
                                import(/* webpackChunkName: "security" */ `@/views/mycenter/manage/security`)
                        },

                        //订单相关
                        {
                            path: '/biddingDetail',
                            name: 'biddingDetail',
                            component: () =>
                                import(/* webpackChunkName: "biddingDetail" */ `@/views/mycenter/deal/biddingDetail`)
                        },
                        {
                            path: '/purchaseOrder',
                            name: 'purchaseOrder',
                            component: () =>
                                import(/* webpackChunkName: "purchaseOrder" */ `@/views/mycenter/deal/purchaseOrder`)
                        },

                        {
                            path: '/orderDetail/:id',
                            name: 'orderDetail',
                            component: () =>
                                import(/* webpackChunkName: "orderDetail" */ `@/views/mycenter/deal/orderDetail`)
                        },
                        {
                            path: '/tenderOrder',
                            name: 'tenderOrder',
                            component: () =>
                                import(/* webpackChunkName: "tenderOrder" */ `@/views/mycenter/deal/tenderOrder`)
                        },
                        {
                            path: '/myNeeds',
                            name: 'myNeeds',
                            component: () =>
                                import(/* webpackChunkName: "myNeeds" */ `@/views/mycenter/deal/myNeeds`)
                        },
                        {
                            path: '/collect',
                            component: () =>
                                import(/* webpackChunkName: "collect" */ `@/views/mycenter/collect/basic`),
                            children: [
                                {
                                    name: 'shopCollect',
                                    path: '/shopCollect',
                                    component: () =>
                                        import(/* webpackChunkName: "collect" */ `@/views/mycenter/collect/shopCollect`),
                                },
                                {
                                    name: 'proCollect',
                                    path: '/proCollect',
                                    component: () =>
                                        import(/* webpackChunkName: "collect" */ `@/views/mycenter/collect/proCollect`),
                                }
                            ]
                        },
                        {
                            path: '/myCollect',
                            name: 'myCollect',
                            component: () =>
                                import(/* webpackChunkName: "myCollect" */ `@/views/mycenter/collect/myCollect`)
                        },
                        {
                            path: '/notice',
                            name: 'notice',
                            component: () =>
                                import(/* webpackChunkName: "notice" */ `@/views/mycenter/message/notice`)
                        },
                        {
                            path: '/myPrice',
                            name: 'myPrice',
                            component: () =>
                                import(/* webpackChunkName: "myPrice" */ `@/views/mycenter/deal/myPrice`)
                        },
                        {
                            path: '/myAfter',
                            name: 'myAfter',
                            component: () =>
                                import(/* webpackChunkName: "myAfter" */ `@/views/mycenter/deal/myAfter`)
                        },
                        {
                            path: '/myComment',
                            name: 'myComment',
                            component: () =>
                                import(/* webpackChunkName: "myComment" */ `@/views/mycenter/deal/myComment`)
                        },

                        {
                            path: '/transaction',
                            name: 'transaction',
                            component: () =>
                                import(/* webpackChunkName: "transaction" */ `@/views/mycenter/message/transaction`)
                        },
                        //我的推广
                        {
                            path: '/generalize',
                            name: 'generalize',
                            component: () =>
                                import(/* webpackChunkName: "transaction" */ `@/views/mycenter/generalize/generalize`)
                        },
                        //积分兑换
                        {
                            path: '/integral',
                            name: 'integral',
                            component: () =>
                                import(/* webpackChunkName: "integral" */ `@/views/mycenter/integral/integral`)
                        },
                        // 用户中心-有订单
                        {
                            path: '/personOrder',
                            name: 'personOrder',
                            component: () =>
                                import(/* webpackChunkName: "integral" */ `@/views/mycenter/personOrder/index`)
                        },
                        // 用户中心-无订单
                        {
                            path: '/noPersonOrder',
                            name: 'noPersonOrder',
                            component: () =>
                                import(/* webpackChunkName: "integral" */ `@/views/mycenter/personOrder/noPersonOrder/index`)
                        }
                    ]
                }
            ]
        },

        {
            path: '/list',
            name: 'list',
            component: import(/* webpackChunkName: "integral" */ `@/components/list/index.vue`)
        },
        // 登录注册相关
        {
            path: '/login',
            name: 'login',
            component: () =>
                import(/* webpackChunkName: "login" */ `@/views/login/login`)
        },
        {
            path: '/register',
            name: 'register',
            component: () =>
                import(/* webpackChunkName: "register" */ `@/views/login/register`)
        },
        {
            path: '/forget',
            name: 'forget',
            component: () =>
                import(/* webpackChunkName: "forget" */ `@/views/login/forget`)
        },
        {
            path: '/binding',
            name: 'binding',
            component: () =>
                import(/* webpackChunkName: "binding" */ `@/views/login/binding`)
        },
        {
            path: '/code',
            name: 'code',
            component: () =>
                import(/* webpackChunkName: "code" */ `@/views/login/code`)
        },
        // {
        //   path: '/404',
        //   name: 'notFound',
        //   component: () =>
        //     import(/* webpackChunkName: "code" */ `@/views/notFound/notFound`)
        // },
        // {
        //   path: '*', // 此处需特别注意至于最底部
        //   redirect: '/404'
        //   }
    ]
});
