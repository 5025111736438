/*
 * @Author: Rain
 * @Description: 购物车信息
 * @Date: 2019-04-10 17:37:18
 */
import { CART_LIST } from './types';
import app from '@/main';
const state = {
  cartItems: [] //购物车列表
};
const mutations = {
  [CART_LIST](state, payload) {
    state.cartItems = payload;
  }
};
const actions = {
  queryCartList({commit, state}, param) {
    return app.$http.get('cart/findByBuyerId').then(res => {
      const { data } = res.data
      commit(CART_LIST, data)
      return data
    })
  }
};
const getters = {
  cartItems: state => state.cartItems
};
export default {
  state,
  mutations,
  actions,
  getters
};
