const imagePath = 'https://imag.kqwad.com/';
export default {
  install(Vue, options) {
    /**
     * @description: 图片路径前缀处理
     * @param {type}
     * @return:
     */
    Vue.filter('imagePath', val => {
      return `${imagePath}${val}`;
    });
    /**
     * @description: 价格格式化
     * @param {type}
     * @return:
     */
    Vue.filter('price', (val, suffix = 2) => {
      return Number(val).toFixed(suffix);
    });
    /**
     * @description: 去逗号
     * @param {type}
     * @return:
     */
    Vue.filter('removeComma', (val, suffix = 2) => {
      return val.replace(/,/g, '');
    });
    /**
     * @description: 手机号格式化
     * @param {type}
     * @return:
     */
    Vue.filter('phoneFmt', (val) => {
      if (val && val.length > 0) {
        let str = val.split('')
        str.splice(3, 4, '****')
        return str.join('');
      } else {
        return val
      }
    });
    /**
     * @description: 比价单状态
     * @param {type}
     * @return:
     */
    Vue.filter('compareStatus', item => {
      if (item === 0) {
        return '比价中'
      } else if (item === 1) {
        return '已完成'
      } else if (item === 2) {
        return '已关闭'
      } else{
        return '--'
      }
    })
     /**
     * @description: 比价单店铺状态
     * @param {type}
     * @return:
     */
    Vue.filter('storeStatus', item => {
      if (item === 0) {
        return '等待商家报价'
      } else if (item === 1) {
        return '选择TA'
      } else if (item === 2) {
        return '中标'
      } else if(item === 3) {
        return '已关闭'
      } else {
        return "已失效"
      }
    })
    /**
     * @description: 订单状态格式化
     * @param {type}
     * @return:
     */
    Vue.filter('orderStatus', item => {
        //通过状态码改变内容，在上方调用
        if (item == 0) {
          return '待付款'
        }
        if (item == 1) {
          return '制作中'
        }
        if (item == 2) {
          return '待收货'
        }
        if (item == 3) {
          return '交易完成'
        }
        if (item == 4) {
          return '交易关闭'
        }
        if (item == 5) {
          return '退款中'
        }
    })
  }
};
