/*
 * @Author: Rain
 * @Description: 用户信息state
 * @Date: 2019-04-10 17:37:18
 */
import { USER } from './types'
const state = {
  user: {}, //用户信息
  isLogin: 0 //是否登录
};
const mutations = {
  [USER](state, payload) {
    state.user = payload
    state.isLogin = localStorage.getItem('TOKEN') ?  1 : 0
  }
};
const actions = {};
const getters = {
  user: state => state.user,
  isLogin: state => state.isLogin
};
export default {
  state,
  mutations,
  actions,
  getters
};
