export default{
    GETCODE: 'buyer/getRegisterCode', //获取手机验证码
    REGISTER: 'buyer/register', //注册
    LOGIN: 'auth/login', //登录
    queryDetail: 'product/store/detail', //查询商品详情(根据商家)
    queryProduct: 'store/product', //查询店铺商品
    compare: 'inviteInfo/addInviteInfo', //商品比价
    ADDCART: 'cart/add', //添加购物车
    SECURITY: 'buyer/alterPass', //修改账户密码
    PHONELOGIN: 'buyer/phone/register' //手机号快速登录
}
