/*
 * @Author: Rain
 * @Description: 商品信息
 * @Date: 2019-04-10 17:37:18
 */
import { KEYWORDS } from './types';
const state = {
  keywords: [] //根据关键字查询
};
const mutations = {
  [KEYWORDS](state, payload) {
    state.keywords = payload;
  }
};
const actions = {
  
};
const getters = {
  keywords: state => state.keywords
};
export default {
  state,
  mutations,
  actions,
  getters
};
